import { ApiOptions, postRequest } from '../index'

interface UploadImageRequest {
  imgBase64: string
}
interface UploadImageResponse {
  url: string
}
export const uploadImage = ({ imgBase64 }: UploadImageRequest) => {
  return postRequest<UploadImageResponse>(
    new ApiOptions({
      url: '/net/api/x/uploadImage',
      args: { imgBase64 },
      timeout: 0,
    }),
  )
}
