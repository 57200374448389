import { getPromise } from '@shared/util/promise'
import { fileToBase64 } from '@shared/util/img'
import { USER_ROLE } from '@shared/constants/user'
import { getUploadToken } from '@common/api/px/file'
import { uploadImage } from '@common/api/api/file'
import { getPartner } from '@common/router'
import { getUserInfo } from '@common/api/api/account'

export const uploadImg = (file: string) => {
  const { promise, resolve, reject } = getPromise()
  if (typeof file === 'string') {
    uploadImage({ imgBase64: file.replace(/data:image\/.+;base64,/, '') })
      .then(res => resolve(res?.data?.url))
      .catch(() => reject())
  } else {
    fileToBase64(file, (base64 = '') => {
      uploadImage({ imgBase64: base64.replace(/data:image\/.+;base64,/, '') })
        .then(res => resolve(res?.data?.url))
        .catch(() => reject())
    })
  }
  return promise
}

interface TokenOptions {
  resourceDirectoryType?: number
}
export const getFileToken = (file: File, tokenOption: TokenOptions = {}) => {
  let bucket = 'school_material'
  const { partnerId } = getPartner()
  return getUserInfo().then((res) => {
    const schools = (res.data?.userSchools || []).filter(
      userSchool => Number(userSchool.organizationId) === Number(partnerId),
    )
    let school = schools.filter(
      userSchool => userSchool.userRole !== +USER_ROLE.Student,
    )[0]
    if (!school) {
      bucket = 'user'
      school = schools.filter(
        userSchool => userSchool.userRole === +USER_ROLE.Student,
      )[0]
    }
    return getUploadToken({
      file,
      schoolId: school?.id || 0,
      bucket,
      resourceDirectoryType: tokenOption.resourceDirectoryType,
    })
  })
}
