import { ApiOptions, getRequest } from '../index'

interface GetUploadTokenRequest {
  schoolId: number
  resourceDirectoryType?: number | undefined
  bucket: string
  file: File
}
interface UploadTokenResponse {
  downloadUrl: string
  token: string
  key: string
  url: string
}
export const getUploadToken = ({
  file,
  schoolId,
  bucket,
  resourceDirectoryType,
}: GetUploadTokenRequest) => {
  return getRequest<UploadTokenResponse>(
    new ApiOptions({
      needCache: false,
      url: '/net/course/Svc/GetUploadToken',
      args: {
        suffix: file.name.split('.').pop(),
        resourceDirectoryType,
        timestamp: Date.now(),
        schoolId,
        bucket,
      },
    }),
  )
}
